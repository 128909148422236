import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { Icon } from '../../../common/icon'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage5() {
  const question = {
    title: 'Frage 5 von 35',
    headline: '',
    subline:
      'Patienten mit dramatischen/herausfordernden Krankheitsverläufen...',
  }

  const answers = [
    {
      name: 'Krankheitsverlauf',
      id: 'gern',
      value: '... betreue ich besonders gern.',
      desc: '',
    },
    {
      name: 'Krankheitsverlauf',
      id: 'alltag',
      value: '... gehören für mich zum Arbeitsalltag dazu.',
      desc: '',
    },
    {
      name: 'Krankheitsverlauf',
      id: 'nope',
      value: '... möchte ich eher nicht betreuen.',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-turkis-waves">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="turkis"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="yellow"
              index={5}
            />
          </div>
        </section>

        <MatchingNavigation
          color="turkis"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-6`}
          prev={`${AREA_CARE_PATH}/spielen/frage-4`}
          index={5}
        />

        <Icon position="3" name="suitcase" />
        <Icon position="4" name="sinus-curve" />
      </main>
    </>
  )
}
